<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="1000px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules"   label-width="230px">

                <el-form-item label="名字" prop="name">
                    <el-input  v-model="ruleForm.name"  />
                </el-form-item>


                <el-form-item label="原价(元)" prop="originalPrice">
                    <el-input  v-model="ruleForm.originalPrice"  />
                </el-form-item>

                <el-form-item label="现价(元)" prop="realPrice">
                    <el-input  v-model="ruleForm.realPrice"  />
                </el-form-item>

                <el-form-item label="算力" prop="walletBalance">
                    <el-input  v-model="ruleForm.walletBalance"></el-input>
                    <span>注：{{ruleForm.walletBalance>0?ruleForm.walletBalance+'算力 = '+(ruleForm.walletBalance/10000).toFixed(2)+'元':"1算力 = 0.0001元"}}</span>
                    <span class="walletBalance-remarks" @click="remarksShow">
                        <el-tooltip class="item" effect="dark" content="该算力全部使用单项AI功能的可使用量预估" placement="top">
                            <i class="el-icon-info"></i>
                        </el-tooltip>
                        <span>{{ruleForm.walletBalance}}算力各AI功能可使用量预估表格</span>
                    </span>
                    <div v-if="showRemarks">
                        <el-form-item>
                            <el-table :data="dictionary" style="width: 100%">
                                <el-table-column prop="name" label="AI功能">
                                </el-table-column>
                                <el-table-column prop="name" label="模型成本">
                                    <template slot-scope="scope"> {{ (scope.row.price/10000*10000) +'算力/'+(getModelBaseNum(scope.row.unitType)==1?"":getModelBaseNum(scope.row.unitType))+getModelUnitStr(scope.row.unitType) }}</template> 
                                </el-table-column>
                                <el-table-column prop="price" label="可使用量">
                                    <template slot-scope="scope"> 
                                        {{ (Math.floor((ruleForm.walletBalance/10000)/(scope.row.price/10000)*getModelBaseNum(scope.row.unitType)))+getModelUnitStr(scope.row.unitType) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </div>
                </el-form-item>
                
                <!-- <div v-if="ruleForm.models">
                    <div class="tips">提醒：增量包的模型只允许选一种</div>
                    <el-form-item label="" prop="years" v-for="(item, index) in modelList" :key="index">
                        <ModelSet :ref="`ModelSet${index}`" :hidePrice="true"></ModelSet>
                    </el-form-item>
                </div> -->

                <!-- <el-form-item label="最多应用数量" prop="appNumLimit">
                    <el-input  v-model="ruleForm.appNumLimit"  />
                </el-form-item>

                
                <el-form-item label="使用次数限制" prop="countLimit">
                    <el-input  v-model="ruleForm.countLimit"  />
                </el-form-item>


                <el-form-item label="最多成员数量" prop="memberNumLimt">
                    <el-input  v-model="ruleForm.memberNumLimt"  />
                </el-form-item>

                
                <el-form-item label="最多训练字数" prop="wordNumLimit">
                    <el-input  v-model="ruleForm.wordNumLimit"  />
                </el-form-item> -->
                

                <el-form-item label="是否启用" prop="status">
                    <el-select v-model="ruleForm.status" style="width:100%" placeholder="请选择" @change="selectChange">
                        <el-option label="启用" :value="1" />
                        <el-option label="禁用" :value="2" />
                    </el-select>
                </el-form-item>

            </el-form>
        <!-- 表单结束 -->
        <!--操作按钮-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"  @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
  
  </template>
  
  <script>
import { getModelUnitText_s, getModelBaseNumber_s } from '@/utils/constant'
import ModelSet from '../orgPruduct/ModelSet.vue'
  // 引入组件及方法
  // 引入组件
  export default {
    components: { ModelSet },
    // 数据
    data() {
      return {
        ruleForm:{
            name:"",
            originalPrice:0,
            realPrice:0,
            walletBalance:0,
            appNumLimit:'',
            countLimit:'',
            memberNumLimt:'',
            wordNumLimit:'',

            status:1//状态：1 启用，2 禁用
        },
        rules: {
            name: [{ required: true, message: '不能为空', trigger: 'blur' }],
            originalPrice: [{ required: true, message: '不能为空', trigger: 'blur' }],
            realPrice: [{ required: true, message: '不能为空', trigger: 'blur' }],
            walletBalance: [{ required: true, message: '不能为空', trigger: 'blur' }],
            appNumLimit: [{ required: true, message: '不能为空', trigger: 'blur' }],
            countLimit: [{ required: true, message: '不能为空', trigger: 'blur' }],
            memberNumLimt: [{ required: true, message: '不能为空', trigger: 'blur' }],
            wordNumLimit: [{ required: true, message: '不能为空', trigger: 'blur' }],

        },
        isEdit:false,
        proId:"",
        extensionsCheckbox:[],
        modelList:[],
        dictionary:[],
        showRemarks:false,
      }
    },
    props:{
        visible:Boolean
    },
    watch: {
        visible(){
            if(this.visible){

            }
        }
    },
    //  初始化
    async mounted() {

        await this.loadAllModel()
      
    },
  
    methods: {
        getModelUnitStr(unitType){
            return getModelUnitText_s(unitType)
        },

        getModelBaseNum(unitType){
            return getModelBaseNumber_s(unitType)
        },
        remarksShow (){
            this.showRemarks = !this.showRemarks
        },

        async start(obj, id){

            if(obj){
                this.ruleForm = obj
                this.isEdit = true
                this.proId = id
                // if(!this.ruleForm.models){
                //     return
                // }
                // await this.initModel()
                // this.modelList.forEach((val, index)=>{
                //     var has = this.ruleForm.models.hasOwnProperty(val.modelValue)
                //     if(has){
                //         val.checked = true
                //         val.number = this.ruleForm.models[val.modelValue].number
                //         if(this.ruleForm.models[val.modelValue].hasOwnProperty("price")){
                //             val.price = this.ruleForm.models[val.modelValue].price/10000
                //         }else{
                //             val.price = 0
                //         }
                //     }
                //     this.$nextTick(()=>{
                //         this.$refs[`ModelSet${index}`][0].init(val)
                //     })
                // })
            }else{
                this.isEdit = false
                this.ruleForm.name = ''
                this.ruleForm.originalPrice = 0
                this.ruleForm.realPrice = 0
                this.ruleForm.walletBalance = 0
                this.ruleForm.appNumLimit = '0'
                this.ruleForm.countLimit = '0'
                this.ruleForm.memberNumLimt = '0'
                this.ruleForm.wordNumLimit = '0'
                this.ruleForm.status = 1
                // this.ruleForm.models = ''
            }
        },
        async initModel(){

            await this.loadAllModel()

            this.modelList = []
            this.extensionsCheckbox.forEach((val)=>{
                var item = Object.assign(val)
                item.checked = false
                item.modelName = val.name
                item.modelValue = val.modelValue
                item.unitType = val.unitType
                this.modelList.push(item)
            })

            this.$nextTick(()=>{
                this.modelList.forEach((val, index)=>{
                    this.$refs[`ModelSet${index}`][0].init(val)
                })
            })

        },
        async loadAllModel(){
            var res = await this.$https('getModels', {})
            let dictionary = res.data
            this.dictionary = dictionary.filter(item => item.id !== '3');
            this.extensionsCheckbox = []
            res.data.forEach((val)=>{
                this.extensionsCheckbox.push({
                    label: parseInt(val.modelValue),
                    modelValue: parseInt(val.modelValue),
                    name: val.name,
                    unitType: val.unitType
                })
            })
        },

        handleClose(){
            this.$emit('update:visible', false)
        },
        handleSubmit(){

            // if(!this.checkCount()){
            //     this.$message.warning('必须至少有一个次数大于0')
            //     return
            // }

            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    
                    // var models = this.createModelsParam()

                    var param = {
                        goodsOrgIncPackage:{
                            name:this.ruleForm.name,
                            originalPrice:this.ruleForm.originalPrice*100,
                            realPrice:this.ruleForm.realPrice*100,
                            walletBalance: this.ruleForm.walletBalance,
                            appNumLimit:this.ruleForm.appNumLimit,
                            countLimit:this.ruleForm.countLimit,
                            memberNumLimt:this.ruleForm.memberNumLimt,
                            wordNumLimit:this.ruleForm.wordNumLimit,
                            // models:models,
                        },
                        goodsType:4,
                        status:this.ruleForm.status,
                        onlyNewUser:0
                    }

                    if(this.isEdit){
                        param.id = this.proId

                        this.$https('updateGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }else{
                        
                        this.$https('addGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                    
                } else {
                    
                }
            });

        },

        //appNumLimit,countLimit,memberNumLimt,wordNumLimit必须有一个不为0
        checkCount(){
            var appNumLimit = this.ruleForm.appNumLimit
            if(parseInt(appNumLimit) > 0){
                return true
            }
            var countLimit = this.ruleForm.countLimit
            if(parseInt(countLimit) > 0){
                return true
            }
            var memberNumLimt = this.ruleForm.memberNumLimt
            if(parseInt(memberNumLimt) > 0){
                return true
            }
            var wordNumLimit = this.ruleForm.wordNumLimit
            if(parseInt(wordNumLimit) > 0){
                return true
            }
            return false
        },
        
        createModelsParam(){
            var obj = {}

            this.modelList.forEach((val, index)=>{
                var item = this.$refs[`ModelSet${index}`][0].getInfo()
                if(item.checked){
                    obj[item.modelValue+""] = {modelValue:item.modelValue, number:item.number, price:item.price*10000}
                }
            })
            return obj

        },
        selectChange(){
            this.$forceUpdate()
        }

    }
  }
  </script>
  
  <style lang="scss" scoped>
  
.tips{
    text-align: center;
    color: #f55;
    padding-bottom: 25px;
}
  

.walletBalance-remarks {
    padding: 0 10px;
    color: #F56C6C;
    cursor: pointer;
}
  </style>
  