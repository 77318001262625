<template>
    <div>

        <el-button type="primary" class="head-opt" @click="addClickBtn">添加组织套餐</el-button>

            
            <el-table :data="tableData" style="width: 100%" border>

                <el-table-column
                    prop="name"
                    label="名字">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.name }}
                    </template>
                    
                </el-table-column>
                
                <el-table-column
                    prop="realPrice"
                    label="现价(元)">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.realPrice }}
                    </template>
                </el-table-column>
                
                <el-table-column
                    prop="originalPrice"
                    label="原价(元)">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.originalPrice }}
                    </template>
                </el-table-column>
                
                <!-- <el-table-column
                    prop="appNumLimit"
                    label="最多应用数量">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.appNumLimit }}
                    </template>
                </el-table-column> -->


                <!-- <el-table-column
                    prop="countLimit"
                    label="使用次数限制">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.countLimit }}
                    </template>
                </el-table-column> -->

                <!-- <el-table-column
                    prop="memberNumLimt"
                    label="最多成员数量">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.memberNumLimt }}
                    </template>
                </el-table-column> -->

                <!-- <el-table-column
                    prop="wordNumLimit"
                    label="最多训练字数">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.wordNumLimit }}
                    </template>
                </el-table-column> -->
                

                <el-table-column
                    prop="status"
                    label="状态">
                    <template slot-scope="scope">

                        <div v-if="scope.row.status == 1" class="yes">启动</div>
                        <div v-if="scope.row.status == 2" class="no">禁用</div>

                    </template>
                </el-table-column>

                <el-table-column
                    prop="walletBalance"
                    label="算力">
                    <template slot-scope="scope">
                        {{ scope.row.goodsOrgIncPackage.walletBalance }}
                    </template>
                    
                </el-table-column>

                <!-- <el-table-column
                    prop="models"
                    label="功能">
                    <template slot-scope="scope">
                        
                        {{ getModelNameList(scope.row.models) }}

                    </template>
                </el-table-column> -->

                <el-table-column
                    fixed="right"
                    label="操作"
                    width="220">
                    <template slot-scope="scope">

                        <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                            编辑
                        </el-button>

                    </template>
                </el-table-column>
            
            </el-table>

            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNumber"
                :page-sizes="[20, 50, 100, 150]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

            <AddIncrementalPruductPay :visible.sync="AddMoneyPayVisible" @ok="getData" ref="AddMoneyPayRef"></AddIncrementalPruductPay>


    </div>
</template>
  
  
<script>

    import { deepClone } from "@/utils/utils";
import ModelSet from "../orgPruduct/ModelSet.vue";
import AddIncrementalPruductPay from "./AddIncrementalPruductPay.vue"
  
  export default {
    components: { AddIncrementalPruductPay},
    data() {
      return {
        AddMoneyPayVisible:false,
        tableData:[],
        total:0,
        pageNumber:1,
        pageSize:40,
        queryParams:{},
        modelLibrary:[]

        
      };
    },
    async mounted() {


        this.$https('getModels', {}).then(res => {
            this.modelLibrary = res.data
            this.getData()
        })



    },
    methods: {
        getData() {
            
            this.$https('queryGoods', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                goodsType:4,
                ...this.queryParams
            }).then(res => {

                res.data.records.forEach((val)=>{
                    val.goodsOrgIncPackage.realPrice = val.goodsOrgIncPackage.realPrice/100
                    val.goodsOrgIncPackage.originalPrice = val.goodsOrgIncPackage.originalPrice/100

                    Object.assign(val, val.goodsOrgIncPackage)
                })
                this.tableData = res.data.records


                this.total = parseInt(res.data.total)


            })
        },
        addClickBtn(){
            this.AddMoneyPayVisible = true
            this.$nextTick(()=>{
                this.$refs.AddMoneyPayRef.start()
            })
        },
        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },
        editBtn(item){
            this.AddMoneyPayVisible = true
            this.$nextTick(()=>{
                item.goodsOrgIncPackage.status = item.status

                this.$refs.AddMoneyPayRef.start(deepClone(item.goodsOrgIncPackage), item.id)
            })

        },
        getModelNameList(models){

            var arr = []

            for(let key in models){
                var item = models[key]
                var res = this.modelLibrary.find((val)=>{
                    return val.modelValue == item.modelValue
                })
                if(res){
                    arr.push(res.name)
                }
            }

            return "【" + arr.join("】, 【") + "】"
            
        }

    },
  };
</script>
  
<style scoped >
.dialogWrap{
    padding: 20px 0 20px 80px;
    box-sizing: border-box;
    
}
.radioWrap{
    padding: 10px 0;
}
.yes{
    color: rgb(75, 167, 0);
}
.no{
    color: rgb(255, 79, 79);
}
.head-opt{
    margin-bottom: 10px;
}
</style>
  